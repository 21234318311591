.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;

    .login-box {
        width: 400px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .login-title {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
        }
        .login-form {
            .form-group {
                margin-bottom: 20px;
                label {
                    display: block;
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                input {
                    width: 100%;
                    padding: 10px;
                    font-size: 16px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
            }
            .form-group:last-child {
                margin-bottom: 0;
            }
            .login-btn {
                width: 100%;

                button {
                    width: 100%;
                    padding: 10px;
                    font-size: 16px;
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s;
                    &:hover {
                        background-color: #0056b3;
                    }
                    &:active {
                        background-color: #004286;
                    }
                }
            }
        }
    }
}
