.job-list {
    width: auto;
    background-color: #f9f9f9;
    padding: 10px;

    button {
        width: auto;
        padding: 10px 30px;
        color: black;
        background-color: #d9d9d9;
        border: 1px solid #b9b9b9;
        border-radius: 3px;

        &:hover {
            background-color: #b9b9b9;
        }
        &:active {
            background-color: #a2a2a2;
            color: white;
        }

        &.active {
            background-color: #4caf50;
            color: white;
        }
        &.success {
            background-color: #0000ff;
            color: white;
        }
        &.fail {
            background-color: #f44336;
            color: white;
        }
    }
}
