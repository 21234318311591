.reg-form {
    width: auto;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    gap: 1px;

    .form-line {
        position: relative;
        background-color: #f2f2f2;
        width: auto;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        padding: 10px;

        .form-label {
            width: 120px;
            min-width: 120px;
            text-align: right;
        }
        .form-input {
            width: auto;
            flex: 1;
            font-size: 13px;
            color: grey;

            div {
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: center;
                justify-content: left;
            }

            label {
                margin-left: 5px;
                cursor: pointer;
                color: gray;
            }
            input:checked + label {
                width: auto;
                color: black;
                font-weight: bold;
            }

            .input-textarea {
                width: 700px;
                padding: 5px;
                font-size: 17px;
                border: 1px solid #a2a2a2;
            }
            input {
                width: auto;
                border: 1px solid #a2a2a2;
                background-color: #fff;
                height: 30px;
                padding: 3px 5px;

                &.input-email {
                    width: 300px;
                }
                &.input-name {
                    width: 200px;
                }
                &.input-text {
                    width: 700px;
                }
                &.input-date {
                    width: 300px;
                }
                &.input-youtube-link {
                    width: 500px;
                }
                &.input-youtube-id {
                    width: 150px;
                    background-color: #f2f2f2;
                    color: white;
                    margin-left: 3px;
                }
                &.input-addrkey {
                    width: 350px;
                }
                &.input-keyword {
                    width: 500px;
                    color: blue;
                }
            }
            button {
                // margin: 0 2px;
                width: auto;
                height: 30px;
                padding: 3px 15px;
                background-color: #d9d9d9;
                color: #000000;
                border: 1px solid #a2a2a2;
                cursor: pointer;
                &:hover {
                    background-color: #888888;
                    color: #000000;
                }
                &.red {
                    color: red;
                }
                &.blue {
                    color: blue;
                }

                &.auto-input,
                &.reset-input {
                    width: auto;
                    padding: 2px 10px;
                    // margin-left: 3px;
                }
            }

            .delete-msg-cloud-image {
                width: 700px;
                padding: 5px;
                font-size: 17px;
                border: 1px solid #a2a2a2;

                height: auto;

                color: #000000;

                display: flex;
                flex-direction: row;
                gap: 5px;

                overflow-x: auto;

                .item {
                    position: relative;
                    width: 100px;
                    height: 100px;
                    padding: 5px;

                    border: 1px solid #a2a2a2;
                    // display: inline-block;

                    &:hover {
                        border: 1px solid red;
                    }

                    &.selected {
                        border: 1px solid red;
                        &::after {
                            content: '✔';
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: red;
                            font-size: 50px;
                            text-shadow: 1px 1px 1px #fff;
                        }
                    }
                    .click-area {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: red;
                        opacity: 0.1;
                        cursor: pointer;
                    }
                }
            }
        }

        &.youtube-thumb {
            display: none;
        }
        #youtube-thumbnail {
            position: relative;

            // display: inline-block;
        }
        .close {
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;
            background-image: url('../img/btn-document-close.svg');
            background-position: center;
            background-size: cover;
            background-color: red;
            cursor: pointer;
        }

        &.image-preview,
        &.delete-image-preview {
            display: none;

            #image-preview {
                // text-align: center;
                // display: flex;
                // flex-direction: row;
                // gap: 10px;
                // justify-content: center;

                .image-box {
                    display: inline-block;
                    background-color: #fff;
                    position: relative;
                    margin: 5px;

                    img {
                        // max-width: 500px;
                        min-width: 100px;
                        min-height: 100px;
                        max-height: 300px;
                        max-width: 300px;
                    }
                    .text {
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        max-width: 190px;
                        background-color: rgba(200, 200, 200, 0.5);
                        border: 1px solid rgba(88, 88, 88, 0.4);

                        color: #000;
                        font-size: 12px;
                        padding: 2px 5px;
                        border-radius: 5px;

                        &.red {
                            color: red;
                        }
                    }
                }
            }
            .image-preview-tmp {
                height: 0px;
                width: auto;
                background-color: yellow;
                overflow: scroll;
                opacity: 0;
            }
        }
    }

    &.ads-config {
        display: none;

        &.active {
            display: flex;
        }
    }
}

.form-submit {
    margin-top: 10px;
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        width: auto;
        min-width: 200px;
        height: auto;
        padding: 10px 30px;
        background-color: #d9d9d9;

        &:hover {
            background-color: #888888;
        }
        &:active {
            background-color: #000000;
            color: #ffffff;
        }
    }
}
