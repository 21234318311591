table.user-list-top {
    width: 100%;
    border-collapse: collapse;

    // margin-top: 20px;
    margin-bottom: 5px;

    td {
        padding: 8px;
        font-size: 13px;
        text-align: right;

        &.left {
            text-align: left;
        }

        select {
            width: 100px;
            height: 30px;
            margin-right: 10px;
            font-size: 14px;
        }
        input {
            width: 70px;
            height: 30px;
            margin-right: 10px;
            font-size: 14px;
            text-align: right;
            padding: 0 5px;
        }

        .search-form {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: right;

            input {
                width: 300px;
                height: 30px;
                margin-right: 10px;
                font-size: 14px;
                padding: 0 5px;
                text-align: left;
                border: 1px solid grey;
            }

            button {
                width: 100px;
                height: 30px;
                font-size: 14px;
                background-color: skyblue;
                color: #000;
                border: 1px solid skyblue;
                cursor: pointer;
                border-radius: 5px;

                &:hover {
                    background-color: rgb(63, 63, 254);
                    color: #fff;
                }
                &:active {
                    background-color: blue;
                    color: #fff;
                }
            }
        }
    }
}
table.user-list {
    width: 100%;
    // border-collapse: collapsek;
    border-collapse: collapse;
    border-spacing: 1px;
    // margin-top: 20px;
    margin-bottom: 20px;
    // border: 1px solid #000;
    // background-color: #d9d9d9;

    // tr 에 마우스 올렸을 때 배경색 변경

    tr {
        &.selected {
            background-color: #f2f2f2;
        }
        &:hover {
            background-color: #f2f2f2;
        }
    }

    th {
        background-color: #d9d9d9;
        color: #333;
        font-size: 14px;
        font-weight: bold;
        padding: 8px;
        text-align: center;
    }
    td {
        border: 1px solid #d9d9d9;
        border-collapse: collapse;
        padding: 8px;
        // border-bottom: 1px solid #ddd;
        // background-color: #f9f9f9;
        font-size: 14px;
        text-align: center;
        cursor: default;

        span {
            width: auto;
            padding: 5px 10px;
            color: blue;
            border-radius: 20px;
            background-color: white;
            border: 1px solid blue;
            font-size: 11px;
            &.normal {
                display: none;
            }

            &.black {
                color: #fff;
                border: 1px solid #fff;
                background-color: black;
            }
            &.red {
                color: #fff;
                border: 1px solid #fff;
                background-color: red;
            }
            &.admin {
                color: white;
                font-weight: bold;
                background-color: green;
                border: 1px solid #fff;
            }
        }

        &.thumb {
            position: relative;
            width: 60px;
            height: 60px;
            img {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 50px;
                height: 50px;
                border-radius: 5px;
                z-index: 100;

                &:hover {
                    z-index: 101;
                    width: 300px;
                    height: unset;
                    border-radius: 10px;
                    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
}

table.user-list-bottom {
    width: 100%;
    border-collapse: collapse;

    margin-bottom: 20px;

    td {
        padding: 0;
        font-size: 13px;

        &.left {
            text-align: left;
        }
        &.right {
            text-align: right;
        }

        button {
            width: 100px;
            height: 30px;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #000;
            cursor: pointer;
            margin-left: 10px;
        }

        div.page-nav-btn {
            display: inline-block;
            background-color: #fff;
            color: #000;
            border: 1px solid #000;
            padding: 10px;
            min-width: 40px;
            height: 40px;
            margin-left: 5px;
            cursor: pointer;
            text-align: center;

            &.page-nav-curr {
                background-color: #e6e6e6;
            }

            &:hover {
                background-color: #e6e6e6;
            }
            &:active {
                background-color: #d9d9d9;
            }

            &.active {
                font-weight: bold;
                background-color: #e6e6e6;
            }
        }
    }
}

.push-noti {
    width: auto;
    // background-color: #d9d9d9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    gap: 5px;

    .line {
        width: 100%;
        padding: 5px;
        // background-color: #e6e6e6;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
        &.title {
            background-color: transparent;
            border: 1px solid #d9d9d9;
            border-width: 0 0 1px 0;
        }

        .head {
            font-size: 14px;
            font-weight: bold;
            width: 100px;
            padding: 5px;
        }
        .body {
            width: 100%;
            // background-color: yellow;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: left;

            &.col {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;

                p {
                    font-size: 14px;
                    color: blue;
                }
            }

            input {
                border: 1px solid grey;
                background-color: #fff;
                padding: 5px;
                height: 30px;

                &.subject {
                    width: 500px;
                }
                &.param {
                    width: 400px;
                }
            }

            label {
                padding-left: 5px;
                color: #c9c9c9;
            }

            input + label {
                margin-right: 25px;
            }

            input[type='radio']:checked + label {
                color: #000;
                font-weight: bold;
            }

            textarea {
                width: 500px;
                height: 100px;
                border: 1px solid grey;
                padding: 5px;
            }

            select {
                width: 100px;
                height: 30px;
                padding: 5px;
                margin-right: 10px;
                font-size: 14px;
            }
            button {
                width: 250px;
                height: 50px;
                font-size: 14px;
                background-color: skyblue;
                color: #000;
                border: 1px solid skyblue;
                cursor: pointer;
                border-radius: 5px;

                &:hover {
                    background-color: rgb(63, 63, 254);
                    color: #fff;
                }
                &:active {
                    background-color: blue;
                    color: #fff;
                }
            }
        }
    }
}
