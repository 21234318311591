.company-info-container {
    display: none;
}

table {
    width: 100%;
    border-collapse: collapse;

    // margin-top: 20px;
    margin-bottom: 5px;

    td {
        padding: 8px;
        font-size: 13px;
        text-align: right;

        &.left {
            text-align: left;
        }
        &.center {
            text-align: center;
        }

        button {
            width: 100px;
            height: 30px;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #000;
            cursor: pointer;
            // margin-left: 10px;
        }
    }
}
table.company-list {
    width: 100%;
    // border-collapse: collapsek;
    border-collapse: collapse;
    border-spacing: 1px;
    // margin-top: 20px;
    margin-bottom: 20px;
    // border: 1px solid #000;
    // background-color: #d9d9d9;

    // tr 에 마우스 올렸을 때 배경색 변경
    tr:hover {
        background-color: #f2f2f2;
    }

    th {
        background-color: #d9d9d9;
        color: #333;
        font-size: 14px;
        font-weight: bold;
        padding: 8px;
        text-align: center;
    }
    td {
        border: 1px solid #d9d9d9;
        border-collapse: collapse;
        padding: 8px;
        // border-bottom: 1px solid #ddd;
        // background-color: #f9f9f9;
        font-size: 14px;
        text-align: center;
        cursor: default;
    }
}

table.company-list-bottom {
    width: 100%;
    border-collapse: collapse;

    margin-bottom: 20px;

    td {
        padding: 0;
        font-size: 13px;

        select {
            width: 100px;
            height: 30px;
            margin-right: 10px;
            font-size: 14px;
        }
        input {
            width: 70px;
            height: 30px;
            margin-right: 10px;
            font-size: 14px;
            text-align: right;
            padding: 0 5px;
        }
        button {
            width: 100px;
            height: 30px;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #000;
            cursor: pointer;
            margin-left: 10px;
        }

        div.page-nav-btn {
            display: inline-block;
            background-color: #fff;
            color: #000;
            border: 1px solid #000;
            padding: 10px;
            min-width: 40px;
            height: 40px;
            margin-left: 5px;
            cursor: pointer;
            text-align: center;

            &.page-nav-curr {
                background-color: #e6e6e6;
            }

            &:hover {
                background-color: #e6e6e6;
            }
            &:active {
                background-color: #d9d9d9;
            }

            &.active {
                font-weight: bold;
                background-color: #e6e6e6;
            }
        }
    }
}

table.company-info-table {
    width: 100%;
    background-color: #d9d9d9;

    tr {
    }

    td {
        &.head {
            width: 100px;
            text-align: center;
        }

        &.body {
            background-color: #f2f2f2;
            width: auto;
            padding: 5px;
            display: flex;
            flex-direction: row;
            gap: 5px;
            justify-content: left;
            align-items: center;
        }
        input {
            width: auto;
            border: 1px solid #a2a2a2;
            background-color: #fff;
            height: 30px;
            padding: 3px 5px;

            &.input-no {
                width: 100px;
                &:read-only {
                    background-color: #f2f2f2;
                }
            }
            &.input-email {
                width: 300px;
            }
            &.input-company {
                width: 400px;
            }
            &.input-text {
                width: 300px;
            }
            &.input-ceo {
                width: 200px;
            }
            &.input-youtube-link {
                width: 500px;
            }
            &.input-youtube-id {
                width: 150px;
                background-color: #f2f2f2;
                color: white;
                margin-left: 3px;
            }
            &.input-addrkey {
                width: 350px;
            }
            &.input-keyword {
                width: 500px;
                color: blue;
            }
        }
    }
}

table {
    width: 100%;
    tr {
        td.center {
            text-align: center;
        }
    }
}
