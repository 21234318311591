.container {
    position: relative;
    width: 100vw;
    height: 100vh;
    font-family: 'Noto Sans KR', 'Roboto', sans-serif;

    -webkit-user-select: none !important;
    /* Safari */
    -ms-user-select: none !important;
    /* IE 10 and IE 11 */
    user-select: none !important;
    /* Standard syntax */

    &.noscroll {
        overflow: hidden;
    }
}

.main-container {
    width: auto;
    // background-color: #f2f2f2;
    height: 100vh;

    .main-head {
        background-color: #d9d9d9;
        height: 30px;
        text-align: center;
        align-items: center;
        color: #000000;
    }

    .main-body {
        background-color: #f2f2f2;
        width: 100%;
        height: calc(100vh - 50px - 30px);

        .main-body-left {
            float: left;
            background-color: #f2f2f2;
            width: 200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: space-between;
            justify-content: space-between;

            div {
                width: 100%;
                padding: 1px;
            }

            .left-menu-item {
                background-color: #fff;
                height: 50px;
                width: 100%;
                color: #000000;
                margin-bottom: 1px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                &.active {
                    background-color: #000000;
                    color: #ffffff;
                    font-weight: bold;
                }

                &.logout {
                    background-color: #ffe9e9;
                    color: red;
                    font-weight: bold;

                    &:hover {
                        background-color: #ffcccc;
                    }

                    &:active {
                        background-color: #ff9999;
                    }
                }

                &:hover {
                    background-color: #d9d9d9;
                }

                &:active {
                    background-color: #000000;
                    color: white;
                }
            }
        }

        .main-body-middle {
            margin-left: 200px;
            width: auto;
            height: 100%;
            background-color: #fff;
            padding: 20px;
            overflow-y: auto;

            .body-content {
                width: 100%;

                .search-body {
                    width: 100%;
                    margin-bottom: 10px;
                    background-color: #f2f3ed;
                    flex-direction: row;
                    display: flex;

                    .search-label {
                        width: 120px;
                        min-width: 120px;
                        text-align: right;
                    }

                    .form-input {
                        width: 300px;
                        flex: 1;
                        font-size: 13px;
                        color: grey;

                        div {
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            align-items: center;
                            justify-content: left;
                        }

                        .input-textarea {
                            width: 300px;
                            padding: 5px;
                            font-size: 17px;
                            border: 1px solid #a2a2a2;
                        }

                        input {
                            width: auto;
                            border: 1px solid #a2a2a2;
                            background-color: #fff;
                            height: 30px;
                            padding: 3px 15px;

                            &.input-name {
                                width: 300px;
                            }

                            &.input-text {
                                width: 300px;
                            }

                            &.input-regdate {
                                width: 300px;
                            }

                            &.input-keyword {
                                width: 300px;
                                color: blue;
                            }
                        }
                    }

                    .search-submit {
                        height: 30px;
                        //display: flex;
                        justify-content: center;
                        align-items: center;

                        button {
                            width: 120px;
                            height: 130px;
                            margin-left: 5px;
                            background-color: #7a83df;
                            font-size: 20px;

                            &:hover {
                                background-color: #888888;
                            }

                            &:active {
                                background-color: #000000;
                                color: #ffffff;
                            }
                        }
                    }
                }

                .search-condition-form {
                    width: 100%;
                    flex-direction: row;
                    display: flex;
                    margin-bottom: 10px;

                    .check_form {
                        width: 100%;
                        margin-left: 1px;
                    }

                    .radio_form {
                        align-items: right;
                        width: 410px;
                        //margin-right: 10px;
                    }
                }

                table.story-list {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 1px;
                    margin-bottom: 20px;

                    tr:hover {
                        background-color: #f2f2f2;
                    }

                    th {
                        background-color: #c2c2c2;
                        border: 1px solid #d9d9d9;
                        border-collapse: collapse;
                        color: #333;
                        font-size: 14px;
                        font-weight: bold;
                        padding: 2px;
                        text-align: center;
                    }

                    td {
                        border: 1px solid #d9d9d9;
                        border-collapse: collapse;
                        padding: 8px;
                        // border-bottom: 1px solid #ddd;
                        // background-color: #f9f9f9;
                        font-size: 14px;
                        text-align: center;
                        cursor: default;

                        span.ads {
                            color: blue;
                            font-weight: bold;
                        }

                        &.content {
                            text-align: left;
                            min-width: 200px;
                            max-width: 200px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }

                        &.user-name {
                            text-align: center;
                            min-width: 100px;
                            max-width: 100px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }

                table.story-list-bottom {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    background-color: #f2f3ed;

                    td {
                        padding: 0;
                        font-size: 13px;

                        &.left {
                            text-align: left;
                        }

                        select {
                            width: 100px;
                            height: 30px;
                            margin-right: 10px;
                            font-size: 14px;
                        }

                        input {
                            width: 70px;
                            height: 30px;
                            margin-right: 10px;
                            font-size: 14px;
                            text-align: right;
                            padding: 0 5px;
                        }

                        button {
                            width: 100px;
                            height: 30px;
                            font-size: 14px;
                            background-color: #fff;
                            border: 1px solid #000;
                            cursor: pointer;
                            margin-left: 10px;
                        }

                        div.page-nav-btn {
                            display: inline-block;
                            background-color: #fff;
                            color: #000;
                            border: 1px solid #000;
                            padding: 10px;
                            min-width: 40px;
                            height: 40px;
                            margin-left: 5px;
                            cursor: pointer;
                            text-align: center;

                            &.page-nav-curr {
                                background-color: #e6e6e6;
                            }

                            &:hover {
                                background-color: #e6e6e6;
                            }

                            &:active {
                                background-color: #d9d9d9;
                            }

                            &.active {
                                font-weight: bold;
                                background-color: #e6e6e6;
                            }
                        }
                    }
                }

                .detail-label {
                    margin: 20px 0 5px 0;
                }

                .form-detail {
                    width: 100%;
                    margin-top: 10px;
                    flex-direction: row;
                    display: flex;
                    padding: 10px 0 20px 0;
                    background-color: #f2f3ed;
                    flex-wrap: wrap;
                    gap: 20px;
                    //flex: 1;

                    .detail-label {
                        width: auto;
                        height: 30px;
                        text-align: left;
                    }

                    .detail-content {
                        width: 400px;
                        flex-direction: column;
                        display: flex;
                        margin-left: 30px;

                        .detail-textarea {
                            width: 420px;
                            height: 200px;

                            textarea {
                                width: 400px;
                                height: 200px;
                                border: 1;
                                resize: none;
                                font-size: 18px;
                            }
                        }

                        .detail-addrkey {
                            display: flex;
                            flex-direction: row;
                            gap: 0px;
                            align-items: center;
                            justify-content: space-between;

                            input {
                                width: 320px;
                                height: 30px;
                                border: 1px solid blue;
                                border-width: 1px 0 1px 1px;
                                font-size: 13px;
                                text-align: left;
                                vertical-align: middle;
                                background-color: #fff;
                                padding: 3px;
                                color: blue;
                                border-radius: 5px 0 0 5px;
                            }

                            button {
                                width: 80px;
                                height: 30px;
                                background-color: #4a5cc3;
                                color: #fff;
                                font-size: 15px;
                                border-radius: 0 5px 5px 0;

                                &:hover {
                                    background-color: #888888;
                                }

                                &:active {
                                    background-color: #000000;
                                    color: #ffffff;
                                }
                            }
                        }

                        .input {
                            width: 300px;
                            border: 1px solid #a2a2a2;
                            background-color: #fff;
                            height: 30px;
                            padding: 3px 15px;

                            &.input-text {
                                width: 700px;
                            }

                            &.input-date {
                                width: 300px;
                            }
                        }
                    }

                    .detail-form {
                        width: auto;
                        flex-direction: column;
                        display: flex;

                        textarea {
                            width: 300px;
                            height: 30px;
                            border: 1;
                            resize: none;
                            font-size: 17px;
                            text-align: left;
                            vertical-align: middle;
                        }

                        detail-id {
                            width: 200px;
                            text-align: center;
                            font-size: 30px;
                            vertical-align: middle;
                        }

                        detail-limit_date {
                            width: 100px;
                            font-size: 17px;
                            vertical-align: middle;
                        }

                        detail-limit_view_cnt {
                            width: auto;
                            font-size: 17px;
                        }

                        detail_view_cnt {
                            width: auto;
                            font-size: 17px;
                        }

                        input[type='number'] {
                            width: 200px;
                            height: 30px;
                            border: 1px solid #000;
                            font-size: 17px;
                            text-align: center;
                            vertical-align: middle;
                            background-color: #fff;
                            padding: 2px 10px;
                            text-align: left;
                        }
                    }

                    .v-line {
                        border-left: thin solid #555353;
                        height: 550px;
                    }

                    .detailkeyword-form {
                        width: 250px;
                        flex-direction: column;
                        display: flex;
                        margin-left: 0px;

                        textarea {
                            width: 100%;
                            height: 30px;
                            border: 1;
                            resize: none;
                            font-size: 17px;
                            text-align: left;
                            vertical-align: middle;

                            &#s_all_keyword {
                                height: auto;
                                padding: 10px;
                            }
                        }

                        .detail-keyword1 {
                            width: 100px;
                            text-align: center;
                            font-size: 20px;
                        }

                        .detail-keyword2 {
                            width: 100px;
                            text-align: center;
                            font-size: 20px;
                        }

                        .detail-keyword3 {
                            width: 100px;
                            text-align: center;
                            font-size: 20px;
                        }

                        .detail-keyword4 {
                            width: 100px;
                            text-align: center;
                            font-size: 20px;
                        }

                        .detail-keyword5 {
                            width: 100px;
                            text-align: center;
                            font-size: 20px;
                        }
                    }

                    .modify-submit {
                        width: 500px;
                        // height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 30px;
                        //flex-direction: column;
                        align-items: center;
                        vertical-align: top;
                        justify-content: flex-start;
                        gap: 10px;

                        button {
                            width: 150px;
                            height: 50px;
                            margin-left: 1px;
                            background-color: #4a5cc3;
                            font-size: 20px;
                            color: #d9d9d9;

                            &.delete {
                                background-color: rgb(229, 16, 16);
                            }

                            &.blind {
                                background-color: grey;
                            }

                            &:hover {
                                background-color: #888888;
                            }

                            &:active {
                                background-color: #000000;
                                color: #ffffff;
                            }
                        }
                    }

                    .reply-submit {
                        width: 500px;
                        //height: 30px;
                        display: flex;
                        flex-direction: column;

                        textarea {
                            width: 300px;
                            height: 30px;
                            border: 1;
                            resize: none;
                            font-size: 17px;
                            text-align: left;
                            vertical-align: middle;
                        }

                        .reply-content {
                            width: 400px;

                            text-align: center;
                            font-size: 20px;

                            textarea {
                                width: 100%;
                            }
                        }

                        reply-username {
                            width: 200px;
                            text-align: center;
                            font-size: 20px;
                        }

                        reply-userEmail {
                            width: 200px;
                            text-align: center;
                            font-size: 20px;
                        }

                        .reply-save-button {
                            width: 150px;
                            height: 50px;
                            margin-left: 0px;
                            margin-top: 210px;
                            background-color: #1c22d5;
                            font-size: 20px;
                            color: #ffffff;


                            &:hover {
                                background-color: #888888;
                            }

                            &:active {
                                background-color: #000000;
                                color: #ffffff;
                            }
                        }
                    }
                }

                .detail-image-viewer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    flex-wrap: wrap;

                    div {
                        position: relative;

                        &.props {
                            display: none;
                            position: absolute;
                            padding: 2px 5px;
                            right: 5px;
                            top: 5px;
                            width: auto;
                            height: auto;
                            background-color: #000000;
                            color: white;
                            font-size: 12px;
                            border: 1px solid #888888;
                            border-radius: 5px;
                        }

                        img+div.props {
                            display: block;
                        }
                    }
                }

                .report-form {
                    table.report-list {
                        width: 100%;
                        border-collapse: collapse;
                        border-spacing: 1px;
                        margin-bottom: 20px;

                        tr:hover {
                            background-color: #f2f2f2;
                        }

                        th {
                            background-color: #d9d9d9;
                            color: #333;
                            font-size: 14px;
                            font-weight: bold;
                            padding: 8px;
                            text-align: center;
                        }

                        td {
                            border: 1px solid #d9d9d9;
                            border-collapse: collapse;
                            padding: 8px;
                            // border-bottom: 1px solid #ddd;
                            // background-color: #f9f9f9;
                            font-size: 14px;
                            text-align: center;
                            cursor: default;
                        }
                    }
                }

                table.story-reply {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 1px;
                    margin-bottom: 20px;

                    tr:hover {
                        background-color: #f2f2f2;
                    }

                    th {
                        background-color: #c2c2c2;
                        border: 1px solid #d9d9d9;
                        border-collapse: collapse;
                        color: #333;
                        font-size: 14px;
                        font-weight: bold;
                        padding: 2px;
                        text-align: center;
                    }

                    td {
                        border: 1px solid #d9d9d9;
                        border-collapse: collapse;
                        padding: 8px;
                        // border-bottom: 1px solid #ddd;
                        // background-color: #f9f9f9;
                        font-size: 14px;
                        text-align: center;
                        cursor: default;

                        span.ads {
                            color: blue;
                            font-weight: bold;
                        }

                        &.content {
                            text-align: left;
                            min-width: 300px;
                            // max-width: 200px;
                            // text-overflow: ellipsis;
                            // white-space: nowrap;
                            // overflow: hidden;
                        }

                        &.user-name {
                            text-align: center;
                            min-width: 100px;
                            max-width: 100px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    .main-foot {
        background-color: #000000;
        height: 30px;
        color: white;
    }
}

.toast-message {
    position: absolute;
    width: auto;
    max-width: 600px;
    height: auto;
    padding: 20px 30px;
    // background-color: rgb(39, 39, 39);
    background-color: #000000;
    border: 1px solid gray;
    border-radius: 5px;
    color: white;
    text-align: center;
    box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // transition: all 0.3s ease-in-out;
    display: none;

    // z-index: -1;
    &.active {
        // opacity: 1;
        display: block;
        z-index: 200;
    }

    p {
        font-size: 14px;
    }

    button.btn-close {
        position: inherit;
        right: 0px;
        top: 0;
        width: 24px;
        height: 24px;
        border-radius: 0 5px 0 0;
        background-image: url('../img/btn-document-close.svg');
        background-position: center;
        background-size: cover;
        background-color: transparent;

        &:hover {
            // background-color: rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.2);
            background-color: grey;
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}

.loading-dim {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 501;

    &.trans {
        background-color: rgba(0, 0, 0, 0);
    }

    &.active {
        display: block;
    }

    .loading {
        position: absolute;
        width: 100px;
        height: 100px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: inherit;
        background: center / contain no-repeat url('../img/loading.svg');
        opacity: 0.1;
    }
}

.reg-form {
    width: auto;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    gap: 1px;

    .form-line {
        position: relative;
        background-color: #f2f2f2;
        width: auto;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        padding: 10px;

        .form-label {
            width: 120px;
            min-width: 120px;
            text-align: right;
        }

        .form-input {
            width: auto;
            flex: 1;
            font-size: 13px;
            color: grey;

            div {
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: center;
                justify-content: left;
            }

            label {
                margin-left: 5px;
                cursor: pointer;
                color: gray;
            }

            input:checked+label {
                width: auto;
                color: black;
                font-weight: bold;
            }

            .input-textarea {
                width: 700px;
                padding: 5px;
                font-size: 17px;
                border: 1px solid #a2a2a2;
            }

            input {
                width: auto;
                border: 1px solid #a2a2a2;
                background-color: #fff;
                height: 30px;
                padding: 3px 15px;

                &.input-email {
                    width: 300px;
                }

                &.input-name {
                    width: 200px;
                }

                &.input-text {
                    width: 700px;
                }

                &.input-date {
                    width: 300px;
                }

                &.input-youtube-link {
                    width: 500px;
                }

                &.input-youtube-id {
                    width: 150px;
                    background-color: #f2f2f2;
                    color: white;
                    margin-left: 3px;
                }

                &.input-addrkey {
                    width: 350px;
                }

                &.input-keyword {
                    width: 500px;
                    color: blue;
                }
            }

            button {
                // margin: 0 2px;
                width: auto;
                height: 30px;
                padding: 3px 15px;
                background-color: #d9d9d9;
                color: #000000;
                border: 1px solid #a2a2a2;
                cursor: pointer;

                &:hover {
                    background-color: #888888;
                    color: #000000;
                }

                &.red {
                    color: red;
                }

                &.blue {
                    color: blue;
                }

                &.auto-input,
                &.reset-input {
                    width: auto;
                    padding: 2px 10px;
                    // margin-left: 3px;
                }
            }
        }

        &.youtube-thumb {
            display: none;
        }

        #youtube-thumbnail {
            position: relative;

            // display: inline-block;
        }

        .close {
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;
            background-image: url('../img/btn-document-close.svg');
            background-position: center;
            background-size: cover;
            background-color: red;
            cursor: pointer;
        }
    }
}

.form-submit {
    margin-top: 10px;
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        width: auto;
        min-width: 200px;
        height: auto;
        padding: 10px 30px;
        background-color: #d9d9d9;

        &:hover {
            background-color: #888888;
        }

        &:active {
            background-color: #000000;
            color: #ffffff;
        }
    }
}