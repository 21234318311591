/* font style */
// @font-face {
//     font-family: "AnyTestFont";
//     src: local("AnyTestFont"), url("AnyTestFont.eot?#iefix") format("embedded-opentype"),
//         url('AnyTestFont.woff') format('woff'),
//         url('AnyTestFont.otf') format('opentype'),
//         url('AnyTestFont.svg') format('svg');
//     font-weight: 500;
//     font-style: normal;
// }

// @import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Dancing+Script&family=Lobster&family=Nanum+Gothic&family=Nanum+Myeongjo&family=Noto+Sans+KR:wght@100..900&family=Noto+Sans:wght@300&family=Noto+Serif+KR:wght@300&family=Noto+Serif:wght@300&family=Open+Sans:wght@300&family=Roboto+Mono:wght@300&family=Roboto+Slab:wght@300&family=Roboto:wght@300&display=swap');

* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 0;
    padding: 0;
    // font-family: 'Noto Sans KR', 'Roboto', sans-serif;
    font-weight: 400;
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: 'Noto Sans KR', 'Roboto', sans-serif;
    // font-weight: 300;
    color: black;
}

body {
    background-color: #ffffff;
}

a,
button {
    cursor: pointer;
    text-decoration: none;
    border: none;
    outline: none;
}

input {
    background: transparent;
    outline: none;
}

ul li,
ol li {
    list-style-type: none;
}

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }
// input[type='number'] {
//     -moz-appearance: textfield;
// }
select:focus {
    outline: none;
}

@import './layout.scss'; // Gnb 및 공통 레이아웃

// input[type='number']::-webkit-inner-spin-button,
// input[type='number']::-webkit-outer-spin-button {
//     -webkit-appearance: inner-spin-button;
//     opacity: 1;
// }

.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 5px solid #f3f3f3;
        border-top: 5px solid #3498db;
        animation: spin 1s linear infinite;
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.grey-font-color {
    color: #666;
}
.blue-font-color {
    color: #3498db;
}
.red-font-color {
    color: #e74c3c;
}
